@import "./../theme/colors.scss";

.default {
  height: 34px;
  padding: 6px 12px;
  display: flex;
  width: 100%;
  border-radius: 4px;
  color: $base-primary-dark;
  font-family: "Inter" !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  border: 1px solid $base-secondary-2;

  &::placeholder {
    font-family: "Inter" !important;
    color: $base-secondary-1;
  }

  &:disabled {
    background-color: $base-secondary-pale !important;
    border: 1px solid $base-secondary-2;

    &:hover,
    &:focus {
      background-color: $base-secondary-pale !important;
      border: 1px solid $base-secondary-2;
    }
  }

  &:hover {
    border: 1px solid $base-secondary-1;
  }

  &:focus {
    border: 1px solid $brand-primary;
    box-shadow: 0px 0px 0px 2px #f0f0ff;
  }
}

.default > input::placeholder {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-secondary-1;
}

.default > input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.default-input > input {
  background: #fff !important;
}

.default-input :-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  background-color: #fff !important;
}

.default-input :-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

.default-input :-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
