@import "./../theme/colors.scss";

.default {
  height: 34px;

  border-radius: 4px !important;
  color: $base-primary-dark;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  &:disabled {
    background-color: $base-secondary-pale !important;
    border: 1px solid $base-secondary-2;

    &:hover,
    &:focus {
      background-color: $base-secondary-pale !important;
      border: 1px solid $base-secondary-2;
    }
  }
}
