$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.modal-settings .ant-modal-content {
  border-radius: 0px;
  padding: 24px !important;
  height: auto;

  @media screen and (min-width: $tablet) {
    padding: 40px;
  }
}

.modal-settings .ant-modal-body {
  height: 100%;
}

.modal-settings .ant-select-selector {
  border-radius: 0px;
}

.payment-collapse .ant-collapse-item .ant-collapse-header {
  padding-left: 0;
}

.payment-collapse .ant-collapse-item .ant-collapse-content-box {
  padding-left: 0;
}
