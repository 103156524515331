$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.modal-add-account .ant-modal-content {
  border-radius: 4px;
  padding: 24px;

  @media screen and (min-width: $s) {
    padding: 40px;
  }
}
