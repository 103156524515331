$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.text-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #505050;
}

.ant-modal {
  height: -webkit-fill-available;
}

.modal-style {
  top: 0;
  overflow: auto;
}

.modal-inner {
  padding: 4px 0px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: $s) {
    padding: 20px 16px;
  }
}
.payment-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 440px;

  @media screen and (min-width: $s) {
    gap: 32px;
  }
}

.inputs-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.heading-row {
  display: flex;
  flex-direction: column;
}

.input-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.select-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 440px;
}
.default-text {
  color: #141414;
}

.pay-inner {
  height: 44px;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  background: #6159ff;
  color: #fff;
}

.add-payment-inner {
  width: 136px;
  height: 30px;
  padding: 6px 8px 6px 12px;
  border-radius: 2px;
  border: 1px solid #505050;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #141414;
}

.payment-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-button {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.remark-text {
  color: #505050;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.text-row {
  display: flex;
  justify-content: space-between;
}

.modal-title {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}

.text-description-input {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
