@import "../../ui-kit/theme/colors.scss";

$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.content-wrapper {
  width: 100%;
  display: flex;

  @media screen and (min-width: $m) {
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
}

.register-block-wrapper {
  display: flex;
  column-gap: 20px;
  height: 100%;

  align-items: center;
  flex-direction: column;

  @media screen and (min-width: $m) {
    justify-content: space-between;
    width: 848px;
  }
}
.inputs-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 14px;

  @media screen and (min-width: $s) {
    width: 100%;
    padding: 0;
    max-width: 448px;
  }
}

.inputs-window {
  display: flex;
  flex-direction: column;
}

.input-item-wrapper {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  gap: 2px;
}

.input-item {
  border-radius: 4px;
  height: 34px;
}

.form-inn {
  width: 100%;
}

.error-mail-text {
  font-size: 12px;
  line-height: 14px;
  color: red;

  @media screen and (min-width: $m) {
    font-size: 16px;
    line-height: 20px;
  }
}

.error-text {
  font-size: 10px;
  line-height: 18px;
  color: $red-primary;
  font-weight: 600;
}
.links-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.stepper-inner {
  width: auto;
  margin-top: 28px;
  height: 300px;
}

.stepper-wrapper {
  display: flex;
  align-items: center;

  @media screen and (min-width: $m) {
    width: 62.6%;
    display: flex;
    flex-direction: column;
    gap: 64px;

    background-color: $brand-secondary-pale;
  }

  @media screen and (min-width: $xl) {
    width: 56%;
  }
}

.button-back {
}

.buttons-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.button-one {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.button-item-wide {
  width: 100%;
}

.button-item-enter {
  width: 100%;
  margin-top: 24px;
}

.input-inn-check {
  width: 230px;

  @media screen and (min-width: $s) {
    width: 331px;
  }
}

.input-inn-completed {
  border: 1px solid $green-primary !important;
}

.button-item-check {
  width: 109px;
}

.inn-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
}

.oferta-text {
  font-family: "Inter";
  color: $brand-primary !important;
  //styleName: 14 Small Text Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.text-row {
  display: flex;
}

.rate-wrapper {
  position: relative;
  padding: 16px 16px 0 16px;
  border-radius: 6px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: $m) {
    padding: 24px 24px 0 24px;
  }
}

.paragraph-text {
  //styleName: 16 Normal Text Regular;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: $base-additional-grey;
}

.rate-wrapper-outer {
  margin-top: 24px;
  border-radius: 8px; /*1*/
  border: 2px solid transparent; /*2*/
  background: linear-gradient(
    180deg,
    #6159ff 0%,
    #1524a6 45%,
    #e600eb 100%,
    #383494 100%
  ); /*3*/

  -webkit-mask-composite: xor; /*5'*/
  mask-composite: exclude; /*5*/
}
.loader-wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.slider-style {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media screen and (min-width: $m) {
    gap: 24px;
  }
}

.checkbox-style {
  text-wrap: nowrap;
  align-items: center;
}

.select-row {
  width: 100%;
}

.select-row-item {
  width: 100%;
}

.row-left {
  width: 150px;
}

.row-right {
  display: flex;
  align-items: flex-start;
  width: 150px;

  @media screen and (min-width: $s) {
    min-width: 250px;
    width: auto;
  }
}

.oferta-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: $base-additional-grey;
}

.oferta-text {
  font-family: "Inter";
  color: $brand-primary !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.text-bold-in {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.input-item-justify {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.default-text {
  color: #141414;
}

.text-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.inputs-row {
  display: flex;
  gap: 8px;

  @media screen and (min-width: $tablet) {
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}

.heading-text {
  font-family: "Inter";
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: $brand-primary;
  word-break: normal;

  @media screen and (min-width: $s) {
    font-size: 42px;
    line-height: 50px;
  }
}

.steps-title {
  font-family: "Inter";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.steps-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.image-register {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 165px;
  height: 79px;

  @media screen and (min-width: $s) {
    margin-top: 12px;
    margin-bottom: 40px;
    width: 453px;
    height: 218px;
    align-self: center;
  }

  @media screen and (min-width: $m) {
    margin-top: 98px;
    margin-bottom: 30px;
    width: 418px;
    height: 200px;
  }
}

.step-active {
  color: $brand-primary;
}

.link-oferta {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $brand-primary !important;
  text-decoration: none !important;
}

.continue-button {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.register-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.register-logo-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logo-stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 14px 24px 14px;
}

.logo-wrapper {
  @media screen and (min-width: $m) {
    margin-top: 30px;
    margin-left: 0px;
    height: 34px;
    align-self: center;
    margin-bottom: 182px;
  }
}

.already-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-secondary-1;
  align-self: center;
}

.input-title-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-secondary-1;
}

.link-auth {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: right;
  color: $brand-primary !important;
}
.already-wrapper {
  display: flex;
  gap: 4px;
  align-self: center;
  margin-bottom: 20px;
}

.text-mail-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.text-mail-second {
  display: flex;
  flex-direction: column;
}

.text-input-title {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.skeleton-input {
  width: 100%;
  height: 12px;
}

.loader-text-block {
  display: flex;
  flex-direction: column;

  width: 100%;
  gap: 8px;
}

.text-title-person {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: $base-additional-grey;
}

.rate-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.rate-description {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.rate-link {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;

  color: $brand-primary !important;
}

.continue-rate {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}

.rate-description-wrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.premium-badge {
  position: absolute;
  top: -22px;
  right: -8px;
  width: 252px;
  height: 40px;
  padding: 0 4px;
  border-radius: 4px 4px 0 4px;
  background: linear-gradient(
      100.04deg,
      #6159ff -41.09%,
      #1524a6 35.31%,
      #e600eb 128.7%,
      #383494 128.7%
    ),
    linear-gradient(0deg, #722ed1, #722ed1);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.premium-arrow {
  position: absolute;
  top: 18px;
  right: -8px;
}

.premium-title {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #fff;
}

.radio-group {
  height: 34px;
  width: 100%;
}

.info-icon-amount {
  color: $brand-primary;
}

.content-style {
  height: 160px;
  color: #fff;
  line-height: 160px;
  text-align: center;
  background: #364d79;
}
