$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.done-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0 16px;

  @media screen and (min-width: $s) {
    padding-bottom: 0 0 40px;
  }
}

.done-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.done-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #505050;
}
