$base-primary-dark: #141414;
$base-additional-grey: #505050;
$base-secondary-1: #8c8c8c;
$base-secondary-2: #d1d1d1;
$base-secondary-pale: #f0f0f0;
$base-color-white: #fff;

$brand-additional-dark: #383494;
$brand-primary: #6159ff;
$brand-secondary-1: #9984ff;
$brand-secondary-2: #ceb7ff;
$brand-secondary-pale: #f0f0ff;

$blue-additional-dark: #1277c1;
$blue-primary: #158fe8;
$blue-secondary-1: #59b9ff;
$blue-secondary-2: #a1d2f6;
$blue-secondary-pale: #ecf9ff;

$green-additional-dark: #135200;
$green-primary: #389e0d;
$green-secondary-1: #73d13d;
$green-secondary-2: #b7eb8f;
$green-secondary-pale: #f6ffed;

$red-additional-dark: #b7003d;
$red-primary: #cf133c;
$red-secondary-1: #e51241;
$red-secondary-2: #ff8095;
$red-secondary-pale: #fff0f3;

$orange-additional-dark: #ff7222;
$orange-primary: #ff8d00;
$oragne-secondary-1: #ffa940;
$orange-secondary-2: #ffd591;
$oragne-secondary-pale: #fff7e6;
