@import "../../../../ui-kit/theme/colors.scss";

$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.text-description {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;
}

.title-font {
  font-family: Inter;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: $base-primary-dark;
}

.title-description {
  font-family: "Inter";
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #505050;

  @media screen and (min-width: $s) {
    font-size: 16px;
    line-height: 24px;
  }
}

.ant-modal {
  height: -webkit-fill-available;
  max-width: 100vw;
}

.modal-style {
  height: 100%;
  top: 0;
  overflow: auto;
}

.modal-inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.operation-inner {
  display: flex;
  flex-direction: column;
  max-width: 440px;

  @media screen and (min-width: $tablet) {
    gap: 24px;
  }
}

.operation-type-inner {
  display: flex;
  flex-direction: column;
}

.inputs-row {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $s) {
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
  }
}

.input-item {
  display: flex;
  flex-direction: column;

  width: 100%;

  @media screen and (min-width: $tablet) {
    gap: 2px;
  }
}

.description-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;

  @media screen and (min-width: $tablet) {
    margin-bottom: 0;
  }
}

.select-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 440px;
}
.default-text {
  color: #141414;
}

.pay-inner {
  width: 100%;
}

.add-payment-inner {
  width: 136px;
  height: 30px;
  padding: 6px 8px 6px 12px;
  border-radius: 2px;
  border: 1px solid #505050;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #141414;
}

.payment-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-button {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.remark-text {
  color: #505050;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.text-row {
  display: flex;
  gap: 48px;
}

.text-left-part {
  width: 120px;
  color: #8c8c8c;
}

.text-right-part {
  text-align: left;
  width: 200px;
}

.form-inner {
  width: 100%;
}

.hint-text {
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #8c8c8c;
}

.error-text {
  color: $red-primary;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
}

.datepicker-style {
  width: 100%;
  padding: 5px 12px !important;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.select-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: $base-primary-dark;
}
