@import "../../../../ui-kit/theme/colors.scss";
@import "../../../../ui-kit/theme/fonts.scss";

$mobile: 320px;
$tablet: 768px;
$s: 1024px;
$m: 1280px;
$l: 1440px;
$xl: 1920px;
$xxl: 2560px;

.text-description {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #505050;
}

.ant-modal {
  height: -webkit-fill-available;
}

.modal-style {
  top: 0;
  overflow: auto;
}

.modal-inner {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.payment-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.inputs-row {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.heading-row {
  display: flex;
  flex-direction: column;
}

.input-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.select-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 440px;
}
.default-text {
  color: #141414;
}

.pay-inner {
  height: 44px;
  padding: 8px 16px;
  border-radius: 4px;
  width: 100%;
  background: #6159ff;
  color: #fff;
}

.add-payment-inner {
  width: 136px;
  height: 30px;
  padding: 6px 8px 6px 12px;
  border-radius: 2px;
  border: 1px solid #505050;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: center;
  color: #141414;
}

.payment-inner {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.footer-button {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.remark-text {
  color: #505050;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.20000000298023224px;
  text-align: left;
}

.text-row {
  display: flex;
  justify-content: space-between;
}

.modal-title {
  font-family: "Inter";
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.text-description-input {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.necessary {
  color: #cf133c;
  font-family: "Inter";
  font-size: 10px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.update-wrapper {
  background: #f0f0ff;

  border-radius: 4px;
  padding: 16px;
}

.update-inner {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 8px;
  flex-direction: column;
  position: relative;
}

.update-text-inner {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.update-text {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.update-text-link {
  font-family: "Inter";
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: $brand-primary !important;
}

.update-taxes-link {
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  color: #6159ff;
}

.banner-title {
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: $brand-additional-dark;
}

.sider-inner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.delete-banner {
  margin-top: 8px;
  border: none;
  position: absolute;
  top: -10px;
  right: -10px;
  background: inherit;

  &:hover {
    background: inherit !important;
  }

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    background: inherit;
  }
}
